<template>
  <Export
      :date_filter="true"
      :entities="entities"
      :campus_filter="true"
      :campuses="campuses"
      :stages_filter="true"
      :user_filter="true"
      :user_type_filter="true"
      :required_filters="['campus']"
      :temperature_filter="true"
      :title="'Covid Assessments'"
      :phase_filter="true"
      :covid_symptoms_filter="true"
  ></Export>
</template>


<script>
import Export from "@/components/exports/Export";
import Campus from "@/models/Campus";

export default {
  name: "covid-assessments",
  components: {
    Export,
  },
  data() {
    return {
      entities: "covid-assessments",
      admissions_team: [],

    };
  },
  computed: {

    campuses() {
      return Campus.query().with('phases').get()
    }
  },
  mounted() {
    Campus.FetchAll({page: 1, limit: 99})
  },
};
</script>
